import { gql } from "@apollo/client";

export const DestinationQuery = gql`
  query DestinationQuery($slug: String) {
    destinations(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          destination_title

          slug
          destination_details {
            region {
              data {
                attributes {
                  region
                  regionName
                }
              }
            }
            # continent {
            #   data {
            #     attributes {
            #       continentName
            #       continent
            #     }
            #   }
            # }

            # ISO_code
            country_short_code
            weather_city_code
          }
          hero_slider {
            id
            sliderHeader
            sliderDescription
            sliderImageAlt
            sliderCredit
            sliderImageLarge {
              data {
                attributes {
                  name
                  url
                }
              }
            }
            video {
              id
              url
              title
              description
              video {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          key_facts {
            sub_item(pagination: { limit: 20 }) {
              sub_heading
              sub_item_description
            }
          }
          transportation {
            category
            text
          }
          accommodation {
            category
            text
          }
          climate {
            category
            text
          }
          money {
            category
            text
          }
          entry_requirements {
            category
            text
          }
          activities {
            information {
              category
              text
            }
            list {
              list_type
              list_item {
                id
                title
                description
                link
                link_target
              }
            }
          }

          search_engine_optimization {
            metaTitle
            metaDescription
            preventIndexing
            metaMedia {
              data {
                attributes {
                  url
                }
              }
            }
          }
          cities {
            data {
              id
              attributes {
                name
                slug
                lat
                lng
              }
            }
          }
        }
      }
    }
  }
`;

export const destinationsCardQuery = gql`
  query DestinationsCardQuery($start: Int, $limit: Int, $regions: [String]) {
    destinations(
      filters: { destination_details: { region: { region: { in: $regions } } } }
      sort: ["destination_details.region.region", "destination_title"]
      pagination: { start: $start, limit: $limit }
    ) {
      data {
        id
        attributes {
          destination_title
          slug
          destination_details {
            region {
              data {
                attributes {
                  region
                  regionName
                }
              }
            }
            country_short_code
          }
          cities {
            data {
              attributes {
                lat
                lng
              }
            }
          }

          search_engine_optimization {
            metaTitle
            metaMedia {
              data {
                attributes {
                  url
                }
              }
            }
            metaDescription
          }
        }
      }
    }
  }
`;

export const relatedExperiencesQuery = gql`
  query RelatedExperienceQuery($destination: String, $limit: Int) {
    experiences(
      filters: { location: { country: { country: { eq: $destination } } } }
      pagination: { limit: $limit }
    ) {
      data {
        id
        attributes {
          experience_title
          slug
          location {
            destination {
              data {
                attributes {
                  slug
                }
              }
            }
            country {
              data {
                attributes {
                  countryName
                }
              }
            }
            cities {
              data {
                attributes {
                  name
                  lat
                  lng
                }
              }
            }
          }
          seo {
            metaDescription
            metaMedia {
              data {
                attributes {
                  url
                }
              }
            }
          }
          experience_categories {
            data {
              attributes {
                category_name
              }
            }
          }
          metadata {
            currencies {
              usd
              eur
              aud
              sgd
              gbp
            }
          }
        }
      }
    }
  }
`;

export const relatedStoriesQuery = gql`
  query RelatedStoriesQuery($destination: String) {
    articles(
      sort: "date_created:desc"
      filters: {
        or: [
          { country: { country: { eq: $destination } } }
          { apply_to_all_destinations: { eq: true } }
        ]
      }
    ) {
      data {
        id
        attributes {
          title
          slug
          publishedAt
          date_created
          createdAt
          updatedAt
          author {
            data {
              attributes {
                authorName
              }
            }
          }
          story_category {
            data {
              attributes {
                category_name
              }
            }
          }
          search_engine_optimization {
            metaMedia {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const destinationCardsByRegionQuery = gql`
  query destinationCardsByRegion($regions: [String]) {
    regions(filters: { region: { in: $regions } }, sort: "region") {
      data {
        attributes {
          experience_title
          slug
          location {
            country {
              data {
                attributes {
                  countryName
                }
              }
            }
          }
          seo {
            metaMedia {
              data {
                attributes {
                  url
                }
              }
            }
          }
          experience_categories {
            data {
              attributes {
                category_name
              }
            }
          }
          metadata {
            currencies {
              usd
              eur
              aud
              sgd
              gbp
            }
          }
        }
      }
    }
  }
`;

export const featuredDestinationsByRegionsQuery = gql`
  query FeaturedDestinationsByRegions($regions: [String]) {
    regions(filters: { region: { in: $regions } }, sort: "region") {
      data {
        id
        attributes {
          region
          regionName
          featuredDestinations {
            data {
              id
              attributes {
                slug
                destination_title
                destination_details {
                  region {
                    data {
                      attributes {
                        region
                      }
                    }
                  }
                }
                search_engine_optimization {
                  metaMedia {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const mapDestinationsQuery = gql`
  query DestinationsPathQuery {
    destinations(pagination: { start: 0, limit: 300 }) {
      data {
        id
        attributes {
          slug
          destination_details {
            country_short_code
            region {
              data {
                attributes {
                  region
                }
              }
            }
          }
        }
      }
    }
  }
`;
