import { graphQlClient } from "lib/graphql-client";
import { site } from "config";
import { processDestinationData } from "./strapi-destinations";
import { LayoutQuery } from "lib/graphql/layout-queries";

export const fetchLayoutData = async () => {
  /* A function that fetches the header/footer data from the Strapi API.
   ** Currently, it's implemented on every individual page since
   ** layout-level data fetching is not yet supported in Next.js.
   */
  try {
    const layoutResponse = await fetch(`${site}/api/layout/new-layout`);
    const { header: headerObject, footer: footerObject } =
      await layoutResponse.json();

    const header = await headerObject?.data?.attributes;
    const footer = await footerObject?.data?.attributes;

    const headerDestinations =
      await header?.destination_menu_destinations?.data?.map((destination) => {
        return processDestinationData(destination, { type: "basic" });
      });

    const data = {
      headerData: {
        ...header,
        headerDestinations: headerDestinations ?? null,
      },
      footerData: { ...footer },
    };

    delete data?.headerData?.destination_menu_destinations;

    return data;
  } catch (error) {
    console.error(error);
    return { headerData: null, footerData: null };
  }
};

export const fetchLayoutDataServer = async () => {
  /* A function that fetches the header/footer data from the Strapi API.
   ** Currently, it's implemented on every individual page since
   ** layout-level data fetching is not yet supported in Next.js.
   */
  try {
    const strapiApolloClient = graphQlClient();

    let { data: layoutData } = await strapiApolloClient.query({
      query: LayoutQuery,
      Authorization: `Bearer ${process.env.STRAPI_API_KEY}`,
    });

    const { header: headerObject, footer: footerObject } = await layoutData;

    const header = await headerObject?.data?.attributes;
    const footer = await footerObject?.data?.attributes;

    const headerDestinations =
      await header?.destination_menu_destinations?.data?.map((destination) => {
        return processDestinationData(destination, { type: "basic" });
      });

    const data = {
      headerData: {
        ...header,
        headerDestinations: headerDestinations ?? null,
      },
      footerData: { ...footer },
    };

    delete data?.headerData?.destination_menu_destinations;

    return data;
  } catch (error) {
    console.error(error);
    return { headerData: null, footerData: null };
  }
};

export const newFetchLayoutData = async () => {
  /* A function that fetches the header/footer data from the Strapi API.
   ** Currently, it's implemented on every individual page since
   ** layout-level data fetching is not yet supported in Next.js.
   */
  try {
    const layoutResponse = await fetch(`${site}/api/layout/layout`);
    const { header: headerObject, footer: footerObject } =
      await layoutResponse.json();

    const header = await headerObject?.data?.attributes;
    const footer = await footerObject?.data?.attributes;

    const headerDestinations =
      await header?.destination_menu_destinations?.data?.map((destination) => {
        return processDestinationData(destination, { type: "basic" });
      });

    const data = {
      headerData: {
        ...header,
        headerDestinations: headerDestinations,
      },
      footerData: { ...footer },
    };

    delete data?.headerData?.destination_menu_destinations;

    return data;
  } catch (error) {
    console.error(error);
    return { headerData: null, footerData: null };
  }
};
