export const setUndefinedPropertiesToNull = (object) => {
  const shallowClone = { ...object };

  const recursiveMutation = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        recursiveMutation(obj[key]);
      } else if (
        (obj[key] == undefined || typeof obj[key] == "undefined") &&
        Object.getOwnPropertyDescriptor(obj, key).writable
      ) {
        obj[key] = null;
      }
    });
  };

  recursiveMutation(shallowClone);

  return shallowClone;
};

export const deleteUndefinedProperties = (object) => {
  /* This function is used to delete undefined properties from an object.
   ** This is mostly used to clean up the data returned from Strapi post-processing,
   ** since sometimes the GraphQL query will not contain fields that the processing
   ** function does, and we don't want to return undefined properties.
   ** This enables us to have a single processing schema to cover all cases
   ** instead of having to write a separate schema for each case.
   */

  const shallowClone = { ...object };

  const recursiveMutation = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        recursiveMutation(obj[key]);
      } else if (
        (obj[key] == undefined || typeof obj[key] == "undefined") &&
        Object.getOwnPropertyDescriptor(obj, key).writable
      ) {
        delete obj[key];
      }
    });
  };

  recursiveMutation(shallowClone);

  return shallowClone;
};

export function findAndReplaceObjectKeys(object, findRegEx, replace) {
  /* Find and replace object keys in an object using a regular expression in String.replace */

  const shallowClone = { ...object };

  const recursiveMutation = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        // If the value is an object, recursively call the function
        recursiveMutation(obj[key]);
      } else if (
        typeof obj[key] === "string" &&
        Object.getOwnPropertyDescriptor(obj, key).writable
      ) {
        // If the value is a string and the property is writable, check if the key matches the regex

        const shouldMutate = findRegEx.test(key);
        if (shouldMutate) {
          // If the key matches the regex, replace the key with the new key
          const newKey = key.replace(findRegEx, replace);
          obj[newKey] = obj[key];

          delete obj[key];
        }
      }
    });
  };

  recursiveMutation(shallowClone);

  return shallowClone;
}

export function findObjectKey(object, targetKey) {
  const recursiveSearch = (obj, targetKey) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === targetKey) {
          // If the key matches, return the value
          return obj[key];
        } else if (
          typeof obj[key] === "object" &&
          obj[key] !== null &&
          key !== targetKey
        ) {
          // If the value is an object (not null), recursively call the function
          const result = recursiveSearch(obj[key], targetKey);
          // If the key was found in the recursive call, return the result
          if (result !== undefined) {
            return result;
          }
        }
      }
    }

    // If the key is not found, return undefined
    return undefined;
  };

  const foundKey = recursiveSearch(object, targetKey);

  return foundKey;
}
