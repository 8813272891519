import { graphQlClient } from "lib/graphql-client.js";

import { API_URL } from "config";
import {
  DestinationQuery,
  relatedExperiencesQuery,
  relatedStoriesQuery,
  destinationsCardQuery,
  featuredDestinationsByRegionsQuery,
  mapDestinationsQuery,
} from "lib/graphql/destination-queries";

import { deleteUndefinedProperties } from "utils/helpers/object-processing";
import { fetchFoodGuideByDestination } from "./strapi-food-guide";

export function processDestinationData(
  destinationObject,
  options = { type: "basic" }
) {
  /* Create standard destination object from Strapi response.
   ** Type: "basic" returns only the basic data--cards, for example.
   ** Type: "full" returns all data, though due to the data.attributes structure
   ** of Strapi, which we don't want on the frontend, we still need to process it.
   */

  const destination = destinationObject?.attributes;
  const details = destination?.destination_details;
  const seo = { ...destination?.search_engine_optimization };

  const basicData = {
    id: destinationObject?.id,
    destination_title: destination?.destination_title,
    slug: destination?.slug,
    date_created: destination?.date_created,
    updatedAt: destination?.updatedAt,
    destination_details: {
      region: {
        ...details?.region?.data?.attributes,
      },
      ISO_code: details?.ISO_code ?? null,
      country_short_code: details?.country_short_code ?? null,
      lngLat: { ...details?.lngLat },
    },
    seo: {
      metaTitle: seo?.metaTitle,
      metaDescription: seo?.metaDescription,
      metaMedia: { ...seo?.metaMedia?.data?.attributes },
    },
    cities: destination?.cities?.data?.map((city) => {
      return { ...city?.attributes };
    }),
  };

  if (options.type === "basic") {
    return deleteUndefinedProperties(basicData);
  }

  const fullData = {
    id: destinationObject?.id,
    destination_title: destination?.destination_title,
    slug: destination?.slug,
    destination_details: {
      region: {
        ...details?.region?.data?.attributes,
      },
      continent: {
        ...details?.continent?.data?.attributes,
      },
      ISO_code: details?.ISO_code ?? null,
      country_short_code: details?.country_short_code ?? null,
      weather_city_code: details?.weather_city_code,
      lngLat: { ...details?.lngLat },
    },
    seo: {
      ...seo,
      metaMedia: { ...seo?.metaMedia?.data?.attributes },
    },
    hero_slider: destination?.hero_slider?.map((slide) => {
      return {
        ...slide,
        sliderImageLarge: { ...slide?.sliderImageLarge?.data?.attributes },
      };
    }),
    videos: destination?.videos,
    key_facts: destination?.key_facts,
    transportation: destination?.transportation,
    accommodation: destination?.accommodation,
    climate: destination?.climate,
    insurance: destination?.insurance,
    money: destination?.money,
    mobile: destination?.mobile,
    community: destination?.community,
    links: destination?.links,
    entry_requirements: destination?.entry_requirements,
    activities: destination?.activities,
    overview: destination?.overview,
    cities: destination?.cities?.data?.map((city) => {
      return { ...city?.attributes };
    }),
  };

  if (options.type === "full") {
    return deleteUndefinedProperties(fullData);
  }
}

export const fetchDestination = async (destinationSlug) => {
  const client = graphQlClient();
  let destinationResponse = await client.query({
    query: DestinationQuery,
    variables: {
      slug: destinationSlug,
    },
  });

  const destinationData =
    await destinationResponse?.data?.destinations?.data[0];

  const relatedExperiencesResponse = await client.query({
    query: relatedExperiencesQuery,
    variables: {
      destination: destinationSlug,
      limit: 30,
    },
  });

  const relatedExperiencesData =
    await relatedExperiencesResponse.data.experiences.data;

  const relatedStoriesResponse = await client.query({
    query: relatedStoriesQuery,
    variables: {
      destination: destinationSlug,
    },
  });

  const relatedStoriesData = await relatedStoriesResponse.data.articles.data;

  const relatedFoodGuideData = await fetchFoodGuideByDestination({
    destinationSlug,
  });

  return {
    destination: destinationData,
    relatedExperiences: relatedExperiencesData,
    relatedStories: relatedStoriesData,
    relatedFoodGuide: relatedFoodGuideData,
  };
};

export const fetchFeaturedDestinations = async () => {
  const response = await fetch(`${API_URL}/home`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${process.env.STRAPI_API_KEY}`,
    },
  });
  const data = await response.json();

  return data.featured_destinations;
};

export const fetchDestinationCards = async (options) => {
  const defaultOptions = {
    pagination: {
      start: 0,
      limit: 300,
    },
    process: true,
  };

  const props = { ...defaultOptions, ...options };

  const { pagination, process } = props;

  const strapiApolloClient = graphQlClient();

  const destinationsCardResponse = await strapiApolloClient.query({
    query: destinationsCardQuery,
    variables: {
      variables: {
        start: pagination?.start,
        limit: pagination?.limit,
      },
    },
  });

  const destinationsData =
    await destinationsCardResponse?.data?.destinations?.data;

  if (process) {
    return destinationsData.map((destination) => {
      return processDestinationData(destination, { type: "basic" });
    });
  }

  return destinationsData;
};

export const fetchDestinationsByRegion = async (options) => {
  const defaultOptions = {
    regions: [],
    featured: false,
    limit: -1,
  };
  const props = { ...defaultOptions, ...options };

  const { regions, featured, limit } = props;

  const strapiApolloClient = graphQlClient();

  const destinationsByRegionsResponse = await strapiApolloClient.query({
    query: featured
      ? featuredDestinationsByRegionsQuery
      : destinationsCardQuery,
    variables: {
      regions,
      limit: limit,
    },
  });

  const destinationsData = (await !featured)
    ? destinationsByRegionsResponse?.data?.destinations?.data
    : destinationsByRegionsResponse?.data?.regions?.data;

  if (process && !featured) {
    return destinationsData.map((destination) => {
      return processDestinationData(destination, { type: "basic" });
    });
  } else if (process && featured) {
    return destinationsData.map((region) => {
      return {
        ...region.attributes,
        featuredDestinations: region.attributes.featuredDestinations.data.map(
          (destination) => processDestinationData(destination)
        ),
      };
    });
  }

  return destinationsData;
};

export async function fetchMapDestinations() {
  const strapiApolloClient = graphQlClient();

  const response = await strapiApolloClient.query({
    query: mapDestinationsQuery,
  });

  const destinationsData = await response?.data?.destinations?.data;

  const processedDestinations = destinationsData.map((destination) => {
    return processDestinationData(destination, { type: "full" });
  });

  return processedDestinations;
}
