// import {
//   ApolloClient,
//   InMemoryCache,
//   createHttpLink,
//   setContext,
// } from "@apollo/client";
// import {
//   ApolloClient,
//   InMemoryCache,
//   createHttpLink,
// } from "@apollo/client/index.js";
import { setContext } from "@apollo/client/link/context/context.cjs";
import { ApolloClient, gql } from "@apollo/client/core/index.js";
import { createHttpLink } from "@apollo/client/link/http/index.js";
import { InMemoryCache } from "@apollo/client/cache/index.js";
import { API_URL } from "../config/index.js";

export const graphQlClient = (props = {}) => {
  const { customHeaders } = props;

  const httpLink = createHttpLink({ uri: `${API_URL}/graphql` });

  const extraHeaders = !!customHeaders ? customHeaders : {};

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...extraHeaders,
        ...headers,
        Authorization: `Bearer ${process.env.STRAPI_API_KEY}`,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    shouldBatch: true,
  });
};

// module.exports = { graphQlClient };
