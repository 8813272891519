import { gql } from "@apollo/client";

export const FoodGuideDestinationQuery = gql`
  query FoodGuideDestinationQuery($destinationSlug: String) {
    foodGuides(
      filters: {
        destination_info: { destination: { slug: { eq: $destinationSlug } } }
      }
    ) {
      data {
        attributes {
          food {
            name
            description
            images {
              data {
                attributes {
                  alternativeText
                  width
                  height
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
