import { graphQlClient } from "lib/graphql-client";
import { FoodGuideDestinationQuery } from "lib/graphql/food-guide-queries";

export async function fetchFoodGuideByDestination({ destinationSlug }) {
  try {
    const strapiApolloClient = graphQlClient();

    const response = await strapiApolloClient.query({
      query: FoodGuideDestinationQuery,
      variables: { destinationSlug },
    });

    const data =
      (await response?.data?.foodGuides?.data[0])?.attributes || null;

    return data;
  } catch (error) {
    console.error(error);
    return { status: 500, error: "Could not load food guides" };
  }
}
