import { gql } from "@apollo/client";

export const LayoutQuery = gql`
  query LayoutQuery {
    header {
      data {
        attributes {
          destination_menu_destinations {
            data {
              attributes {
                destination_title
                slug
                destination_details {
                  region {
                    data {
                      attributes {
                        region
                        regionName
                      }
                    }
                  }
                }
                search_engine_optimization {
                  metaMedia {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    footer {
      data {
        attributes {
          socialLinks {
            whatsapp
            instagram
            facebook
            twitter
            linkedin
            email
            messenger
          }
        }
      }
    }
  }
`;

export const NewLayoutQuery = gql`
  query NewLayoutQuery {
    header {
      data {
        attributes {
          destination_menu_destinations {
            data {
              attributes {
                destination_title
                slug
                destination_details {
                  region {
                    data {
                      attributes {
                        region
                        regionName
                      }
                    }
                  }
                }
                search_engine_optimization {
                  metaMedia {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    footer {
      data {
        attributes {
          socialLinks {
            whatsapp
            instagram
            facebook
            twitter
            linkedin
            email
            messenger
          }
        }
      }
    }
  }
`;
